<template>
  <div :class="$style.root">
    <div :class="$style.spinner"></div>
  </div>
</template>

<style module>
.root {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index--spinner);
}

.spinner {
  width: calc(var(--unit--horizontal) * 5);
  height: calc(var(--unit--horizontal) * 5);
  background-color: var(--color--blue);

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
</style>
